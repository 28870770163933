import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { CURRENT_POLICY, CURRENT_POLICY_PAY_PLAN, CURRENT_POLICY_STATUS } from '@prcins/constants';
import { PaymentProviderService } from '../services/payment-provider.service';

@Component({
  selector: 'prcins-pending-payments',
  templateUrl: './pending-payments.component.html',
  styleUrls: ['./pending-payments.component.css']
})
export class PendingPaymentsComponent implements OnInit {
  
  @Input() currentPaymentDetails: any;
  @Input() pageType: any;
  @Input() currentPayPlan: any;
  @Output() hasPendingPayments = new EventEmitter<boolean>();
  showPendingPayments: boolean=false;
  paymentDetails:any;
  showSinglePymntMsg: boolean=false;
  showMultiplePymntsMsg: boolean=false;
  totalPaymentAmt: any;
  paymentMadeToday: boolean=false;
  paymentMessage: any;
  icon: any;
  paymentMsgClass: any;
  iconWidth: any;
  eftAccountNum: any;
  showEFTSection: boolean=false;
  showEFTMessageOne: boolean=false;
  showEFTMessageTwo: boolean=false;
  showPendingCancellationSection: boolean=false;
  showMortgageeSection: boolean=false;
  showMortMsgOne: boolean=false;
  showMortMsgTwo: boolean=false;
  showHelpMsg: boolean=false;
  divBackground: any;
  agentInfo:any;
  agentName: any;
  policyStatus: any;
  payPlanList = ["EFT", "-E-"];
  currPayPlan;

  constructor(private paymentProviderService: PaymentProviderService) { }

  ngOnInit() {
    
    this.currPayPlan = sessionStorage.getItem(CURRENT_POLICY_PAY_PLAN);
     if(this.payPlanList.some(payPlan => this.currPayPlan.includes(payPlan))){
      this.paymentProviderService.getEFTDetails(sessionStorage.getItem(CURRENT_POLICY), sessionStorage.getItem(CURRENT_POLICY_PAY_PLAN)).subscribe(
        data => {
          this.showEFTSection = false;
        if(this.currentPaymentDetails && !this.currentPaymentDetails.pendingCancellation){
          this.showPendingCancellationSection = false;
          this.pendingPayments(true);
          this.eftAccountNum = data.eftAccountNumber ? data.eftAccountNumber.substring(data.eftAccountNumber.length - 4) : '';
          let currentDate = new Date();
           let paymentDate = new Date(this.currentPaymentDetails.paymentDueDt);
           //let lastPaymentDate = new Date(this.currentPaymentDetails.lastPaymentDate);
           if(paymentDate > currentDate ){
             this.showEFTMessageTwo=true;
             this.showEFTMessageOne=false;
           }else if(this.isDatesEqual(currentDate, paymentDate) || paymentDate < currentDate){
              this.showEFTMessageOne=true;
              this.showEFTMessageTwo=false;
              this.paymentMadeToday=true;
           }
           this.showEFTSection = Number(this.currentPaymentDetails.amountDue) != NaN && Number(this.currentPaymentDetails.amountDue) > 0 ;
         }else if(this.currentPaymentDetails && this.currentPaymentDetails.pendingCancellation){
          this.showPendingCancellationSection = true; 
          this.pendingPayments(false);
          this.showEFTSection = true;
         }
        });
    }else if(sessionStorage.getItem(CURRENT_POLICY_PAY_PLAN).includes("MORTGAGEE")){
      
      if(this.currentPaymentDetails){
        let agentDetails = sessionStorage.agentInfo;
         if (agentDetails) {
           this.agentInfo = JSON.parse(agentDetails);
           this.agentName = this.agentInfo.agencyName;
        }
        this.policyStatus = sessionStorage.getItem(CURRENT_POLICY_STATUS);
        this.pendingPayments(false);
        if(this.currentPaymentDetails.amountDue > 0){
          let inValidStatus = ["cancel","canceled", "cancelled"];
          if(this.policyStatus && inValidStatus.indexOf(this.policyStatus.toLowerCase()) >= 0){
              //hide mortgagee section
          }else{
            this.pendingPayments(true);
            this.showMortgageeSection = true;
            if(!this.currentPaymentDetails.pendingCancellation){
              this.showMortMsgOne = true;
              this.divBackground="px-8 py-4 border mb-8 rounded bg-es-sky-blue"
            }else{
              this.showMortMsgTwo = true;
              this.divBackground="px-8 py-4 border mb-8 rounded border-es-pale-orange bg-es-pale-orange-light"
            }
          }
        }  
      }
    }else{
     this.paymentProviderService.getPendingPaymentsDetails(sessionStorage.getItem(CURRENT_POLICY)).subscribe(data => {
      if(data && data.length > 0){
        this.paymentDetails = data;
        if(_.size(data) > 1){
          this.totalPaymentAmt = data.map(a => a.PAYMENT_AMOUNT).reduce(function(p1, p2)
            {
                return p1 + p2;
            });
            this.showMultiplePymntsMsg = true;
        }else{
           let currentDate = new Date();
           let paymentDate = new Date(this.paymentDetails[0].PAYMENT_PROCESSED_DT);
           if(this.isDatesEqual(currentDate, paymentDate)){
              this.paymentMadeToday=true;
           }
           this.showSinglePymntMsg=true;
        }
        this.pendingPayments(true);
        if(this.currentPaymentDetails && this.currentPaymentDetails.landingPage){
          this.paymentMsgClass = "mr-8";
        }else{
          this.paymentMsgClass = "mr-20";
        }
        this.pageType  == 'Payment History' || this.pageType == 'Future Installments' ? this.icon = "svgs/info-icon.svg": this.icon="png/check-icon.png";
        this.pageType  == 'Payment History' || this.pageType == 'Future Installments' ? this.iconWidth = "w-16": this.iconWidth="w-8";
         
        this.showPendingPayments=true;
        console.log(this.currentPaymentDetails);
      } else{
        this.pendingPayments(false);
      } 
    });
  } 
  }

  pendingPayments(hasPendingPayments) {
     this.hasPendingPayments.emit(hasPendingPayments);
  }

 isDatesEqual(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

}
